import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

import Routes from './Routes';
import '../src/assets/scss/main.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Hint from "../src/assets/json/en/hint.json";
import WhatsAppFloatingIcon from './components/WhatsAppFloatingIcon';
import { isMobileOnly } from 'react-device-detect';
import "slick-carousel/slick/slick.css";
import 'slick-carousel/slick/slick-theme.css';
import 'rsuite/dist/styles/rsuite-default.min.css';
import OfferConsultingForm from './components/Forms/OfferConsultingForm';
import LoginForm from './components/Forms/LoginForm';

const Footer = lazy(() => import('./components/Footer/Footer'));
const MainHeader = lazy(() => import('./components/Header/MainHeader'));
class App extends Component {
    constructor() {
        super()
        this.state = {
            countryRestrict: true,
            whatsAppMessage: "",
            formConsultingForm: false,
            loginForm: false,
            loginFormCounter: sessionStorage.getItem('loginFormCounter') || 0,

        }
    }

    componentDidMount() {
        localStorage.setItem('formIsopened', false);
        this.countryRestrict()
        const WhatsappMessage = Hint.WhatsappMessages[Math.floor(Math.random() * Hint.WhatsappMessages.length)];
        this.setState({
            whatsAppMessage: WhatsappMessage
        })
    }

    countryRestrict = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const paramValue = searchParams.get('__ov_request_key');

        const ipResponse = await fetch("https://ipapi.co/json/", { mode: "cors" });
        const ipData = await ipResponse.json();
        const country = await ipData.country;
        localStorage.setItem("country", country)
        localStorage.setItem("country_details", ipData)

        const check_local_restriction = sessionStorage.getItem('restricted')
        if (check_local_restriction === "true" || check_local_restriction === null) {
            if (country === "MV") {
                if (paramValue === "OVPASS123") {
                    console.log("yes")
                    sessionStorage.setItem('restricted', false)
                    this.setState({
                        countryRestrict: true
                    })

                } else {
                    console.log("No")
                    sessionStorage.setItem('restricted', true)
                    this.setState({
                        countryRestrict: false
                    })
                }
            }
        }
    }

    openConsultingForm = () => {
        const checkEmail = localStorage.getItem('email');
        const checkConsulting = localStorage.getItem('isSendConsulting');
        const formIsopened = localStorage.getItem('formIsopened');
    
        if (formIsopened === "false" && checkConsulting == null && this.state.loginFormCounter < 2) {
            let counterSession = this.state.loginFormCounter + 1
            sessionStorage.setItem('loginFormCounter', counterSession)
            this.setState({
                loginFormCounter:counterSession,
            })
            if (checkEmail == null) {
                    this.setState({
                        loginForm: true,
                        formConsultingForm: false,
                    })
                
            } else {
                this.setState({
                    loginForm: false,
                    formConsultingForm: true,
                });
            }
        }
    }
    

    closeLoginForm = (status, loginCheck) => {
        if (this.state.consultingState) {
            this.setState({
                loginForm: status,
                formConsultingForm: loginCheck,
            });
        } else {
            this.setState({
                loginForm: status,
                formConsultingForm : loginCheck,
            });
        }
    };

    closeExitForm = () => {
        this.setState({
            formConsultingForm: false
        })
    }



    render() {
        return (
            <>
                {this.state.countryRestrict ? (
                    <GoogleOAuthProvider clientId="603766335050-vf4vmgamnpmcrg900an14ievcj3cvf00.apps.googleusercontent.com">
                        <BrowserRouter >
                            <ScrollToTop>
                                <div className="App" onMouseLeave={this.openConsultingForm}>
                                    <Suspense fallback={<></>}>
                                        <MainHeader />
                                    </Suspense>

                                    <div className="body">
                                        <Routes />
                                    </div>
                                    <Suspense fallback={<></>}>
                                        <Footer />
                                    </Suspense>
                                    {/* WhatsApp icon */}
                                    {isMobileOnly && <WhatsAppFloatingIcon />}

                                    {this.state.formConsultingForm && 
                                        <OfferConsultingForm closeExitForm={this.closeExitForm} />
                                    }
                                    {this.state.loginForm &&
                                        <LoginForm closePopup={this.closeLoginForm} />
                                    }

                                </div>
                            </ScrollToTop>
                        </BrowserRouter>
                    </GoogleOAuthProvider>

                ) : ("Restricted")}
            </>


        );
    }
}

export default App;