import React, { useState, useEffect } from "react";
import MediaQuery from "react-responsive";
import { NavLink } from "react-router-dom";
import FormLabel from "@material-ui/core/FormLabel";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { InputNumber, Input } from 'rsuite';
import { Spinner } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function OfferConsultingForm({ closeExitForm }) {
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [selectedTime, setSelectedTime] = React.useState(new Date());

    const [selectedDateText, setSelectedDateText] = React.useState(new Date());
    const [selectedTimeText, setSelectedTimeText] = React.useState(new Date());

    const [value, setValue] = useState()
    const [countryId, setCountryId] = React.useState("");
    const [telNumber, setTelNumber] = React.useState("");

    const handleDateChange = (date) => {
        setSelectedDate(date)
        const originalDate = new Date(date);
        const formattedDate = `${originalDate.getFullYear()}-${(originalDate.getMonth() + 1).toString().padStart(2, '0')}-${originalDate.getDate().toString().padStart(2, '0')}`;
        setSelectedDateText(formattedDate);
    };

    const handleTimeChange = (time) => {
        setSelectedTime(time)
        const originalDate = new Date(time);
        const hours = originalDate.getHours();
        const minutes = originalDate.getMinutes();
        const formattedTime = `${hours % 12}:${minutes.toString().padStart(2, '0')} ${hours >= 12 ? 'PM' : 'AM'}`;
        setSelectedTimeText(formattedTime);
    }

    useEffect(() => {
        localStorage.setItem('formIsopened', true);
        fetch("https://www.discoverymaldives.com/experience/get_location.php", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCountryId(data.country_id);
                setTelNumber(data.telephone_no);
            })
            .catch((error) => {
                console.log("not work");
                console.log(error);
            });

            return () => {
                localStorage.setItem('formIsopened', false);
            };
    }, [])

    const [errorLog, setErrorLog] = useState([]);
    const [isActive, setActive] = useState(false);
    const [telephone, setTelephone] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const formSubmitHandler = (event) => {
        console.log("test")
        let return_status = true;
        let errorList = [];
        setActive(true)
        setSuccessMessage("")
        if (telephone == "") {
            errorList.push(`Phone Number required`);
            return_status = false;
            setActive(false)
        }

        setErrorLog(errorList);
        if (return_status) {
            fetch('https://www.discoverymaldives.com/experience/thank-you.php', { //https://www.discoverymaldives.com/experience/consultation-thankyou.php
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    fldcustomerFax: 0,
                    fldCustomerFormType: 4,
                    fldCustomerFormMessage1: "Consulting Form",
                    fldAdVisitorID: localStorage.getItem("fldAdVisitorID"),
                    adfrom: localStorage.getItem("adfrom"),
                    keyword: localStorage.getItem("keyword"),
                    first_landing_page: localStorage.getItem("first_landing_page"),
                    code: localStorage.getItem("code"),
                    fldCustomerEMail: localStorage.getItem('email'),
                    fldCustomerMobilePhone: telephone,
                    levelOfEnquiry: 1,
                    history: sessionStorage.getItem('pageData'),
                    fldCustomerFormMessage: "Selected Date : " + selectedDateText + " Selected Time : " + selectedTimeText
                })
            }).then((response) => response.json())
                .then(data => {
                    setActive(false)
                    if (data.code == 200) {
                        setSuccessMessage("Request for Consulting - Successfully Sent")
                        localStorage.setItem('isSendConsulting', true);
                    } else {
                        setSuccessMessage("Something went wrong. Please Try Again Later")
                    }
                }).catch((error) => {
                    setActive(false)
                    setSuccessMessage("Something went wrong. Please Try Again Later")
                });
        }
    }

    const ul_style = {
        padding: "0px",
        listStyle: "none",
        margin: "0px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center"
    };

    const offerTitle = {
        textAlign: "center"
    }
    const widthFixed = {
        height: "50px",
    };
    const margin_removed = {
        marginBottom: "0px",
    };


    return (
        <>
            <MediaQuery minDeviceWidth={768}>
                <div className="exit-intent-popup visible">
                    <div className="newsletter ov-custom-schedule-form d-flex justify-content-center">
                        <div className=" ov-bg-white">
                            {/* <div className="col-md-6 p-0 ov-boder left">
                                <div className="main-offer-wrapper">
                                    <div className="main-offer-image">
                                        <img className="main-image" src={offerSeoDetails.main_image} alt="" />
                                    </div>
                                    <div className=" ">
                                        {offerSeoDetails.offer_resorts.map((element, index) => (
                                            <div id="SnglOfrDesc" className="">
                                                <div className="ov-description secondry text-center">
                                                    <p className="ov-description secondry pt-3 " style={margin_removed}>
                                                        {element.resort_name}
                                                    </p>
                                                    {element.rate.is_package &&
                                                        <div className="resort-inner-title-tag-wrapper">
                                                            <span className="offer-pill-wrapper resort-name-tag">
                                                                <span className="resortTag deal-tag">Great Deal</span>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                {element.offer_applicables ? (
                                                    <div className="mt-2">
                                                        <ul style={ul_style}>
                                                            {element.offer_applicables.map((offer_tag, offer_tag_index) => (
                                                                <li className="">
                                                                    <NavLink
                                                                        strict
                                                                        exact
                                                                        to={`${process.env.PUBLIC_URL}/special-offers/${offer_tag.seo_url}`}
                                                                        key={`${process.env.PUBLIC_URL}/special-offers/${offer_tag.seo_url}`}
                                                                    >
                                                                        <span className="ov_button  secondry-button m-2">{offer_tag.tag_name}</span>
                                                                    </NavLink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : ("")}

                                                <div className="">
                                                    <div className="ov-heding small ov-initial text-center mt-2" >
                                                        {element.offer_title} &nbsp; <br />
                                                        {element.rate &&
                                                            <>
                                                                <span className="ov-italic">{element.rate.package_nights}Night Package on{" "}</span>
                                                                <span className="ov-bold">{element.rate.meal_plan_data.meal_plan_text}</span>
                                                                {" "} at {" "}
                                                                <span className="ov-bold">
                                                                    USD{" "}
                                                                    {element.rate.is_package && element.rate.package_rate !== 0
                                                                        ? element.rate.package_rate
                                                                        : element.rate.contract_rate}
                                                                    <span className="">/villa</span>
                                                                </span>
                                                                {element.rate.is_package && element.rate.package_rate !== "0" && element.rate.contract_rate !== "0" ? (<>valued at <span className="text-price-strikethrough"> {element.rate.contract_rate} </span></>) : ("")
                                                                }
                                                            </>
                                                        }
                                                        <h5 className="ov-description primary mt-2">
                                                            Travel Until {element.valid_until}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className=" text-center mt-2 mb-3 ">
                                                    <NavLink
                                                        strict
                                                        exact
                                                        to={`${process.env.PUBLIC_URL}/maldives-luxury-resorts/${element.resort_slug_url}`}
                                                        key={`${process.env.PUBLIC_URL}/maldives-luxury-resorts${element.resort_slug_url}`}
                                                    >
                                                        <div className=" ov_button arrow-button">
                                                            View Resort
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div> */}
                            <div className="">
                                <div className="">
                                    <div className="pl-50 pr-50 ov-input mb-4">
                                        <div>
                                            <p className="ov-description teritory mt-5 pt-4  ">
                                                Schedule Your Free Consultation Today
                                            </p>
                                            {/* <p className="ov-description primary mt-2">
                                                Our Ocean Vistas team will promptly respond via your preferred contact method
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="pl-50 pr-50">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="form-wrapper">
                                                <div className="">
                                                    <div className="mb-3">
                                                        <FormLabel className="margin-top-form mb-0" component="legend">Pick a date</FormLabel>
                                                        <KeyboardDatePicker
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label=""
                                                            format="MM/dd/yyyy"
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <FormLabel className="margin-top-form mb-0" component="legend">Pick A time</FormLabel>
                                                        <KeyboardTimePicker
                                                            margin="normal"
                                                            id="time-picker"
                                                            label=""
                                                            format="HH:mm"
                                                            value={selectedTime}
                                                            onChange={handleTimeChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <FormLabel className="margin-top-form mb-3" component="legend">Confirm your Number *</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            <PhoneInput className="mobilenumberInput w-100 input-font-new"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                defaultCountry={localStorage.getItem('country')}
                                                                value={telephone}
                                                                onChange={setTelephone} />
                                                        </div>


                                                    </div>
                                                    {errorLog.map((errorElement, errorIndex) => (
                                                        <>
                                                            <div className="pl-50 pr-50">
                                                                <p className="ov-description xtra-small font-red">
                                                                    {errorElement}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ))}
                                                    {/* <div className="">
                                                        <FormLabel className="margin-top-form mb-0" component="legend">Confirm your Email *</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            
                                                            <Input className="mobilenumberInput input-font-new" placeholder="Email" size="lg" required />
                                                        </div>


                                                    </div> */}
                                                    {errorLog.map((errorElement, errorIndex) => (
                                                        <>
                                                            <div className="pl-50 pr-50">
                                                                <p className="fnt-15 marg-btm-5 padd-btm-15  body-new">
                                                                    {/* {errorElement} */}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ))}

                                                    <div className="pl-50 pr-50">
                                                        <p className="ov-font-14 body-new">
                                                            {successMessage}
                                                        </p>
                                                    </div>


                                                </div>
                                                <div className="text-center mt-4">
                                                    <button className="ov_button primary-button mb-4" variant="contained" color="primary" type="button" onClick={formSubmitHandler}>free consultation
                                                        <Spinner
                                                            as="span"
                                                            variant="light"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            animation="border"
                                                            className={isActive ? "spinnerSubmitactive" : "spinnerSubmitinactive"}
                                                        /></button>
                                                </div>

                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </div>

                                </div>



                            </div>
                        </div>
                        <button className="close" onClick={closeExitForm}>
                            <button class="enquiry-close-icon">×</button>
                        </button>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery maxDeviceWidth={767}>
                <div className="exit-intent-popup visible">
                    <div className="newsletter ov-custom-schedule-form ov-mobile-popup">
                        <div className="ov-bg-white">
                            {/* <div className="col-md-6 p-0 ov-boder left">
                                <div className="main-offer-wrapper">
                                    <div className="main-offer-image">
                                        <img className="main-image" src={offerSeoDetails.main_image} alt="" />
                                    </div>
                                    <div className=" ">
                                        {offerSeoDetails.offer_resorts.map((element, index) => (
                                            <div id="SnglOfrDesc" className="">
                                                <div className="ov-description secondry text-center">
                                                    <p className="ov-description secondry pt-3 " style={margin_removed}>
                                                        {element.resort_name}
                                                    </p>
                                                    {element.rate.is_package &&
                                                        <div className="resort-inner-title-tag-wrapper">
                                                            <span className="offer-pill-wrapper resort-name-tag">
                                                                <span className="resortTag deal-tag">Great Deal</span>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                {element.offer_applicables ? (
                                                    <div className="mt-2">
                                                        <ul style={ul_style}>
                                                            {element.offer_applicables.map((offer_tag, offer_tag_index) => (
                                                                <li className="">
                                                                    <NavLink
                                                                        strict
                                                                        exact
                                                                        to={`${process.env.PUBLIC_URL}/special-offers/${offer_tag.seo_url}`}
                                                                        key={`${process.env.PUBLIC_URL}/special-offers/${offer_tag.seo_url}`}
                                                                    >
                                                                        <span className="ov_button  secondry-button m-2">{offer_tag.tag_name}</span>
                                                                    </NavLink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ) : ("")}

                                                <div className="">
                                                    <div className="ov-heding small ov-initial text-center mt-2" >
                                                        {element.offer_title} &nbsp; <br />
                                                        {element.rate &&
                                                            <>
                                                                <span className="ov-italic">{element.rate.package_nights}Night Package on{" "}</span>
                                                                <span className="ov-bold">{element.rate.meal_plan_data.meal_plan_text}</span>
                                                                {" "} at {" "}
                                                                <span className="ov-bold">
                                                                    USD{" "}
                                                                    {element.rate.is_package && element.rate.package_rate !== 0
                                                                        ? element.rate.package_rate
                                                                        : element.rate.contract_rate}
                                                                    <span className="">/villa</span>
                                                                </span>
                                                                {element.rate.is_package && element.rate.package_rate !== "0" && element.rate.contract_rate !== "0" ? (<>valued at <span className="text-price-strikethrough"> {element.rate.contract_rate} </span></>) : ("")
                                                                }
                                                            </>
                                                        }
                                                        <h5 className="ov-description primary mt-2">
                                                            Travel Until {element.valid_until}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className=" text-center mt-2 mb-3 ">
                                                    <NavLink
                                                        strict
                                                        exact
                                                        to={`${process.env.PUBLIC_URL}/maldives-luxury-resorts/${element.resort_slug_url}`}
                                                        key={`${process.env.PUBLIC_URL}/maldives-luxury-resorts${element.resort_slug_url}`}
                                                    >
                                                        <div className=" ov_button arrow-button">
                                                            View Resort
                                                        </div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div> */}
                            <div className="">
                                <div className="ov-custom-height">
                                    <div className="pl-50 pr-50 mb-5 ov-input">
                                        <div>
                                            <p className="ov-description teritory mt-4 pt-5  ">
                                                Schedule Your Free Consultation Today
                                            </p>
                                            {/* <p className="ov-description primary mt-2">
                                                Our Ocean Vistas team will promptly respond via your preferred contact method
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="pl-50 pr-50">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <div className="form-wrapper">
                                                <div className="">
                                                    <div className="mb-5">
                                                        <FormLabel className="margin-top-form mb-0" component="legend">Pick a date</FormLabel>
                                                        <KeyboardDatePicker
                                                            margin="normal"
                                                            id="date-picker-dialog"
                                                            label=""
                                                            format="MM/dd/yyyy"
                                                            value={selectedDate}
                                                            onChange={handleDateChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mb-5">
                                                        <FormLabel className="margin-top-form mb-0" component="legend">Pick A time</FormLabel>
                                                        <KeyboardTimePicker
                                                            margin="normal"
                                                            id="time-picker"
                                                            label=""
                                                            format="HH:mm"
                                                            value={selectedTime}
                                                            onChange={handleTimeChange}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change time',
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="mb-5">
                                                        <FormLabel className="margin-top-form mb-2" component="legend">Confirm your Number *</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            <PhoneInput className="mobilenumberInput w-100 input-font-new"
                                                                international
                                                                countryCallingCodeEditable={false}
                                                                defaultCountry={localStorage.getItem('country')}
                                                                value={telephone}
                                                                onChange={setTelephone} />
                                                        </div>


                                                    </div>
                                                    {errorLog.map((errorElement, errorIndex) => (
                                                        <>
                                                            <div className="pl-50 pr-50">
                                                                <p className="ov-description xtra-small font-red">
                                                                    {errorElement}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ))}
                                                    {/* <div className="">
                                                        <FormLabel className="margin-top-form mb-0" component="legend">Confirm your Email *</FormLabel>
                                                        <div className="consulting-input-filed-prepend">
                                                            
                                                            <Input className="mobilenumberInput input-font-new" placeholder="Email" size="lg" required />
                                                        </div>


                                                    </div> */}
                                                    {errorLog.map((errorElement, errorIndex) => (
                                                        <>
                                                            <div className="pl-50 pr-50">
                                                                <p className="fnt-15 marg-btm-5 padd-btm-15  body-new">
                                                                    {/* {errorElement} */}
                                                                </p>
                                                            </div>
                                                        </>
                                                    ))}

                                                    <div className="text-center">
                                                        <p className="mb-4">
                                                            {successMessage}
                                                        </p>
                                                    </div>


                                                </div>
                                                <div className="text-center">
                                                    <button className="ov_button primary-button mb-4" variant="contained" color="primary" type="button" onClick={formSubmitHandler}>free consultation
                                                        <Spinner
                                                            as="span"
                                                            variant="light"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            animation="border"
                                                            className={isActive ? "spinnerSubmitactive" : "spinnerSubmitinactive"}
                                                        /></button>
                                                </div>

                                            </div>
                                        </MuiPickersUtilsProvider>
                                    </div>

                                </div>



                            </div>
                        </div>
                        <button className="close" onClick={closeExitForm}>
                            <button class="enquiry-close-icon">×</button>
                        </button>
                    </div>
                </div>
            </MediaQuery>
        </>
    )
}

export default OfferConsultingForm;

