import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import logoIcon from "../../assets/images/logo/ov-traveller-logo-new.webp";
import googleIcon from "../../assets/images/socialMediaIcons/R-google.svg";
import facebookIcon from "../../assets/images/socialMediaIcons/R-fb.svg";
import TextField from "@material-ui/core/TextField";
import rGoogleIcon from "../../assets/images/socialMediaIcons/R-google.svg";
import rFbIcon from "../../assets/images/socialMediaIcons/R-fb.svg";
import rAdvisorIcon from "../../assets/images/socialMediaIcons/R-tripadvisor.svg";
import rPilotIcon from "../../assets/images/socialMediaIcons/Rpilot.svg";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

function LoginPopupForm({ closePopup }) {
	const [showPopup, setShowPopup] = useState(false);
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const emailRegexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const emailChange = (event) => {
		const newEmail = event.target.value;
		setEmail(newEmail);
		setEmailError(false);
	};

	const emailSaveToDb = () => {
		const apiUrl = 'https://www.discoverymaldives.com/experience/V3/saveEmail.php';

		const data = {
		  email: email
		};
	
		fetch(apiUrl, {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json'
		  },
		  body: JSON.stringify(data) // Convert the data to JSON format
		})
		.then(response => {
		  if (response.ok) {
			console.log('Data sent successfully');
		  } else {
			console.error('Failed to send data');
		  }
		})
		.catch(error => {
		  console.error('Error:', error);
		});
	}

	const loginEmailInputCheck = () => {
		if (validateEmail(email)) {
			localStorage.setItem("email", email);
			setShowPopup(true);
			closePopup(false, true);
		} else {
			setEmailError(true);
			closePopup(true, false);
		}

		emailSaveToDb();
	};

	const validateEmail = (email) => {
		return emailRegexPattern.test(email);
	};

	const login = useGoogleLogin({
		onSuccess: (codeResponse) => {
			fetch(
				"https://www.googleapis.com/oauth2/v3/userinfo?access_token=" +
				codeResponse.access_token
			)
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (typeof data.email !== "undefined") {
						localStorage.setItem("email", data.email);
						setEmail(data.email);
						setEmailError(false);
						setShowPopup(true);
						closePopup(false, true);
						emailSaveToDb();
					}
				});
		},
	});

	const responseFacebook = (response) => {
		if (typeof response.email !== "undefined") {
			setEmail(response.email);
			setEmailError(false);
			localStorage.setItem("email", response.email);
			setShowPopup(true);
			closePopup(false, true);
			emailSaveToDb();
		}
	};

	const handleClosePopUp = () => {
		closePopup(false, false);
	};

	useEffect(() => {
        localStorage.setItem('formIsopened', true);

        return () => {
            localStorage.setItem('formIsopened', false);
        };

    }, []);

	return (
		<div className={`login-popup-container ${showPopup ? "full-screen" : ""}`}>
			<div className="login-popup-body">
				<span>
					<button className="login-close-icon" onClick={handleClosePopUp}>
						&times;
					</button>
				</span>

				<div className="login-logo-icon">
					<img
						src={logoIcon}
						alt="Icon"
						className="icon mb-2"
						style={{ width: "110px", height: "auto" }}
					/>
				</div>

				<div className="first-content-text">
					<p className="ov-description primary ">
						Get in Touch with Us to Unlock the Latest Deal Breakers
					</p>
				</div>

				

				<div className="login-form-content">
					<div className="login-email">
						<TextField
							required
							label="Your Email"
							name="fldCustomerEMail"
							key="fldCustomerEMail"
							id="email"
							onChange={emailChange}
							error={emailError}
							helperText={emailError ? "Invalid email format" : ""}
							
						/>
					</div>

					

					<div className="mt-1">
						<div class="login-or-text">
							<hr />
							<p className="ov-description primary ">or signin with</p>
							<hr />
						</div>

						<div className="login-media-icons">
							<span>
								<img
									src={googleIcon}
									alt="Icon"
									style={{ width: "20px", height: "auto" }}
									onClick={() => login()}
								/>
							</span>
							{/* <span>
                                <img
                                    src={appleIcon}
                                    alt="Icon"
                                    style={{ width: "20px", height: "auto", marginTop: "-3px" }}
                                />
                            </span> */}
							<span>
								<FacebookLogin
									appId="1990421757974951"
									autoLoad={false}
									callback={responseFacebook}
									fields="name,email"
									render={(renderProps) => (
										<img
											src={facebookIcon}
											alt="Icon"
											style={{ width: "20px", height: "auto" }}
											onClick={renderProps.onClick}
										/>
									)}
								/>
							</span>
						</div>
					</div>

					<div>
						<p className="ov-label primary  ov-lh-small mt-3">
							Disclaimer : By proceeding, you agree to be contacted by our
							Vistas who will customise and plan your Maldives holiday. They'll
							provide insights, find the ideal resort, and act as your dedicated
							24/7 personal assistant, curating the best experiences for your
							perfect getaway.
						</p>
					</div>
					<div className="d-grid gap-2 mt-4">
						<button
							type="submit"
							className="ov_button primary-button"
							onClick={loginEmailInputCheck}
						>
							Continue
						</button>
					</div>
					 <div className="r-login-media-icons">
					<span style={{ paddingLeft: "0px" }}>
						{/* <img
							src={rPilotIcon}
							alt="Icon"
							style={{ width: "22px", height: "auto", marginTop: "-4px" }}
						/> */}
						<i class="fa fa-star" aria-hidden="true"></i>
						
						<span className="r-text">5.0</span>
					</span>

					<span style={{ paddingLeft: "20px" }}>
						{/* <img
							src={rGoogleIcon}
							alt="Icon"
							style={{ width: "20px", height: "auto" }}
						/> */}
						<i class="fa fa-google" aria-hidden="true"></i>
						<span className="r-text">4.9</span>
					</span>
					<span style={{ paddingLeft: "20px" }}>
						{/* <img
							src={rFbIcon}
							alt="Icon"
							style={{ width: "20px", height: "auto" }}
						/> */}
						<i class="fa fa-facebook-square" aria-hidden="true"></i>
						<span className="r-text">4.9</span>
					</span>

					<span style={{ paddingLeft: "20px" }}>
						{/* <img
							src={rAdvisorIcon}
							alt="Icon"
							style={{ width: "22px", height: "auto" }}
						/> */}
						<i class="fa fa-tripadvisor" aria-hidden="true"></i>
						<span className="r-text">5.0</span>
					</span>
				</div> 
				</div>
			</div>
		</div>
	);
}

export default LoginPopupForm;
