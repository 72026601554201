import React, { useEffect } from 'react';

const TrackingComponent = () => {
    useEffect(() => {
        
        let pageEntryTime = new Date().getTime();
        const pageData = JSON.parse(sessionStorage.getItem('pageData')) || [];
        pageData.push({
            page: window.location.pathname,
            entryTime: pageEntryTime,
        });
        sessionStorage.setItem('pageData', JSON.stringify(pageData));

    }, []);

    return null;
};

export default TrackingComponent;